export default function SummarySkeletonLoader() {
    return <div className="flex w-full flex-col md:flex-row flex-grow p-2 border border-gray-300 rounded-sm">
        <div className={`flex flex-col px-0 md:px-2 py-2 md:py-0 w-full gap-1 items-center md:w-max flex-grow border-b md:border-b-0 md:border-r md:border-solid border-gray-300`}>
            <div className="text-xs text-gray-500 text-center w-16 h-4 bg-gray-200 animate-pulse"></div>
            <div className="text-xs text-gray-500 text-center w-20 h-5 bg-gray-200 animate-pulse"></div>
        </div>
        <div className={`flex flex-col px-0 md:px-2 py-2 md:py-0 w-full gap-1 items-center md:w-max flex-grow border-b md:border-b-0 md:border-r md:border-solid border-gray-300`}>
            <div className="text-xs text-gray-500 text-center w-16 h-4 bg-gray-200 animate-pulse"></div>
            <div className="text-xs text-gray-500 text-center w-20 h-5 bg-gray-200 animate-pulse"></div>
        </div>
        <div className={`flex flex-col px-0 md:px-2 py-2 md:py-0 w-full gap-1 items-center md:w-max flex-grow`}>
            <div className="text-xs text-gray-500 text-center w-16 h-4 bg-gray-200 animate-pulse"></div>
            <div className="text-xs text-gray-500 text-center w-20 h-5 bg-gray-200 animate-pulse"></div>
        </div>
    </div>
}