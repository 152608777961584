import { useEffect, useState } from "react";
import { useAppContext } from "../context/AppContext";
import { useUser } from "../hooks/useUsers";
import { changePassword } from "../services/LoginService";

export default function Profile() {
    const { setNotification } = useAppContext();

    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [currentPassword, setCurrentPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [repeatNewPassword, setRepeatNewPassword] = useState<string>("");
    const [passwordError, setPasswordError] = useState<string>("");
    const [newPasswordError, setNewPasswordError] = useState<string>("");
    const [repeatPasswordError, setRepeatPasswordError] = useState<string>("");

    const { data: user } = useUser();

    useEffect(() => {
        setFirstName(user?.first_name || "");
        setLastName(user?.last_name || "");
    }, [user]);

    const handleCheckPassword = ({ onChange, value }: { onChange: (val: string) => void; value: string }) => {
        if (!/^(?=.*[A-Za-z])(?=.*\d).{8,}$/.test(value)) {
            setNewPasswordError("A senha deve conter pelo menos 8 caracteres, uma letra e um número");
        } else {
            setNewPasswordError("");
        }
        setPasswordError("");
        onChange(value);
    }

    const handleChangeRepeatPassword = (value: string) => {
        setRepeatNewPassword(value);
        setPasswordError("");
        if (newPassword !== value) {
            setRepeatPasswordError("As senhas não coincidem");
        } else {
            setRepeatPasswordError("");
        }
    }

    const handleUpdatePassword = async () => {
        try {
            if (!currentPassword) {
                setPasswordError("Informe sua senha atual");
                return;
            }
            if (newPassword !== repeatNewPassword) {
                setPasswordError("As senhas não coincidem");
                return;
            }
            if (!/^(?=.*[A-Za-z])(?=.*\d).{8,}$/.test(newPassword)) {
                setPasswordError("A senha deve conter pelo menos 8 caracteres, uma letra e um número");
                return;
            }
            setPasswordError("");
            await changePassword({ old_password: currentPassword, new_password: newPassword });
            setNewPassword("");
            setCurrentPassword("");
            setRepeatNewPassword("");
            setNotification({ message: "Senha atualizada com sucesso", type: "success", title: "Sucesso" });
        } catch (e) {
            setNotification({ message: "Erro ao atualizar senha, verifique os dados informados", type: "error", title: "Erro" });
        }
    }

    const isUpdated = firstName !== user?.first_name || lastName !== user?.last_name;

    return <div className="bg-white shadow sm:rounded-lg">
        {/* <div className="flex flex-row gap-20 flex-wrap px-4 py-5 sm:p-6 border-b border-gray-200"> */}
        <div className="flex flex-row gap-20 flex-wrap px-4 py-5 sm:p-6">
            <div className="flex flex-col gap-2 w-80 min-w-80">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Informações Pessoais</h2>
                <p className="text-sm leading-6 text-gray-400">
                    Atualize os dados do seu perfil.
                </p>
            </div>
            <div className="flex flex-col gap-6 flex-grow max-w-[800px] pb-6">
                <div className="flex flex-row gap-6 flex-wrap">
                    <div className="flex flex-grow flex-col gap-2 min-w-72">
                        <label className="text-sm text-gray-500">Primeiro nome</label>
                        <input type="text" disabled value={firstName} onChange={(e) => setFirstName(e.target.value)} className="w-full border border-gray-200 rounded-md px-3 py-2" />
                    </div>
                    <div className="flex flex-grow flex-col gap-2 min-w-72">
                        <label className="text-sm text-gray-500">Sobrenome</label>
                        <input type="text" disabled value={lastName} onChange={(e) => setLastName(e.target.value)} className="w-full border border-gray-200 rounded-md px-3 py-2" />
                    </div>
                </div>
                <div className="flex flex-grow flex-col gap-2 min-w-72">
                    <label className="text-sm text-gray-500">E-mail</label>
                    <input disabled={true} value={user?.email} type="text" className="w-full border border-gray-200 text-gray-300 rounded-md px-3 py-2" />
                </div>
                <div className="w-full flex justify-end">
                    {/* <button disabled={!isUpdated} className={`${!isUpdated ? "bg-gray-400" : "bg-indigo-700"} rounded-md text-white text-sm py-2 px-4 max-w-24`}>Salvar</button> */}
                </div>
            </div>
        </div>
        <div className="flex flex-row gap-20 flex-wrap px-4 py-5 sm:p-6 border-b border-gray-200">
            <div className="flex flex-col gap-2 w-80 min-w-80">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Alterar Senha</h2>
                <p className="text-sm leading-6 text-gray-400">
                    Aqui você consegue atualizar sua senha da sua conta
                </p>
            </div>
            <div className="flex flex-col gap-6 flex-grow max-w-[800px]">
                <div className="flex flex-grow flex-col gap-2 min-w-72">
                    <label className="text-sm text-gray-500">Senha atual</label>
                    <input value={currentPassword} onChange={(e) => {
                        setPasswordError("");
                        setCurrentPassword(e.target.value)
                    }} type="password" className="w-full border border-gray-200 rounded-md px-3 py-2" />
                </div>
                <div className="flex flex-grow flex-col gap-2 min-w-72">
                    <label className="text-sm text-gray-500">Nova senha</label>
                    <input value={newPassword} onChange={(e) => handleCheckPassword({ onChange: setNewPassword, value: e.target.value })} type="password" className="w-full border border-gray-200 rounded-md px-3 py-2" />
                    {newPasswordError && <div className="text-red-500 text-sm">{newPasswordError}</div>}
                </div>
                <div className="flex flex-grow flex-col gap-2 min-w-72">
                    <label className="text-sm text-gray-500">Repetir nova senha</label>
                    <input value={repeatNewPassword} onChange={(e) => handleChangeRepeatPassword(e.target.value)} type="password" className="w-full border border-gray-200 rounded-md px-3 py-2" />
                    {repeatPasswordError && <div className="text-red-500 text-sm">{repeatPasswordError}</div>}
                </div>
                {passwordError && <div className="text-red-500 text-sm">{passwordError}</div>}
                <div className="w-full flex justify-end">
                    <button disabled={currentPassword === "" || newPassword === "" || repeatNewPassword === ""} onClick={() => handleUpdatePassword()} className="bg-indigo-700 rounded-md text-white text-sm py-2 px-4 max-w-24 disabled:bg-gray-300">Salvar</button>
                </div>
            </div>
        </div>
    </div>
}