import { format, parseISO } from "date-fns";
import { useState } from "react";
import { useDashboard } from "../hooks/useDashboard";
import { refreshSummary } from "../services/DashboardService";
import { Button } from "./Button";
import ExpenseSummary from "./ExpensesSummary";
import LoadingSpinner from "./Loading";

export default function AdminDashboard() {
    const { data, isLoading } = useDashboard();
    const [loading, setLoading] = useState(false);
    const refresh = async () => {
        setLoading(true);
        try {
            await refreshSummary();
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }
    const getGrowthPercentage = (current: number, previous: number) => {
        if (previous === 0) {
            return 0;
        }
        const percentage = (((current - previous) / previous) * 100).toFixed(2);
        return percentage
    }

    const getDifference = (current: number | string, previous: number | string) => {
        return (Number(current) - Number(previous)).toFixed(2);
    }
    const formattedDate = data ? format(parseISO(data.created_at), "dd/MM/yyyy HH:mm:ss") : "";
    return <div className="flex flex-col w-full gap-4">
        <div className="flex flex-row justify-between gap-1">
            <h4 className="text-lg font-semibold">Vendas</h4>
            <div className="flex flex-row gap-2 items-center">
                <h5 className="text-sm text-gray-500">Última atualização: {formattedDate}</h5>
                <Button title="Atualizar" onClick={() => refresh()} className="bg-blue-500 text-white" />
            </div>
        </div>
        {(isLoading || loading) && <LoadingSpinner />}
        {data && !isLoading && !loading && <>
            <ExpenseSummary values={[
                {
                    label: "Usuários Ativos", value: `${data?.users_count}`,
                    badge: data.history.one_day ? `+ ${getDifference(data.users_count, data.history.one_day.users_count)} (${getGrowthPercentage(Number(data.users_count), Number(data.history.one_day.users_count))}%)` : null,
                    className: "text-gray-400"
                },
                {
                    label: "Vendas", value: `${data?.total_sales}`,
                    badge: data.history.one_day ? `+ ${getDifference(data.total_sales, data.history.one_day.total_sales)} (${getGrowthPercentage(Number(data.total_sales), Number(data.history.one_day.total_sales))}%)` : null,
                    className: "text-green-400"
                },
                {
                    label: "Receita",
                    badge: data.history.one_day ? `+ R$${getDifference(data.total_income, data.history.one_day.total_income)} (${getGrowthPercentage(Number(data.total_income), Number(data.history.one_day.total_income))}%)` : null,
                    value: `R$ ${data?.total_income}`, className: "text-green-400"
                },
            ]} />
            <h4 className="text-lg font-semibold">Métricas de uso</h4>
            <ExpenseSummary values={[
                {
                    label: "Videos Assistidos",
                    badge: data.history.one_day ? `+ ${getDifference(data.watched_videos_count, data.history.one_day.watched_videos_count)} (${getGrowthPercentage(Number(data.watched_videos_count), Number(data.history.one_day.watched_videos_count))}%)` : null,
                    value: `${data?.watched_videos_count}`, className: "text-gray-400"
                },
                {
                    label: "Metas Cadastradas", value: `${data?.goals_count}`,
                    badge: data.history.one_day ? `+ ${getDifference(data.goals_count, data.history.one_day.goals_count)} (${getGrowthPercentage(Number(data.goals_count), Number(data.history.one_day.goals_count))}%)` : null,
                    className: "text-gray-400"
                },
                {
                    label: "Despesas Fixas",
                    badge: data.history.one_day ? `+ ${getDifference(data.transactions_count, data.history.one_day.transactions_count)} (${getGrowthPercentage(Number(data.transactions_count), Number(data.history.one_day.transactions_count))}%)` : null,
                    value: `${data?.transactions_count}`, className: "text-gray-400"
                },
                {
                    label: "Transações Registradas",
                    badge: data.history.one_day ? `+ ${getDifference(data.monthly_transactions_count, data.history.one_day.monthly_transactions_count)} (${getGrowthPercentage(Number(data.monthly_transactions_count), Number(data.history.one_day.monthly_transactions_count))}%)` : null,
                    value: `${data?.monthly_transactions_count}`, className: "text-gray-400"
                },
            ]} />
            <h4 className="text-lg font-semibold">Métricas da IA</h4>
            <ExpenseSummary values={[
                {
                    label: "Relatórios Pendentes",
                    badge: data.history.one_day ? `+ ${getDifference(data.ai_summaries_pending_count, data.history.one_day.ai_summaries_pending_count)} (${getGrowthPercentage(Number(data.ai_summaries_pending_count), Number(data.history.one_day.ai_summaries_pending_count))}%)` : null,
                    value: `${data?.ai_summaries_pending_count}`, className: "text-red-400"
                },
                {
                    label: "Relatórios Gerados",
                    badge: data.history.one_day ? `+ ${getDifference(data.ai_summaries_generated_count, data.history.one_day.ai_summaries_generated_count)} (${getGrowthPercentage(Number(data.ai_summaries_generated_count), Number(data.history.one_day.ai_summaries_generated_count))}%)` : null,
                    value: `${data?.ai_summaries_generated_count}`, className: "text-green-400"
                },
            ]} />
        </>}
    </div>
}