import { ArrowLeftCircleIcon, ArrowRightCircleIcon, ClipboardDocumentListIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import { addMonths, format, parseISO } from "date-fns";
import { useState } from "react";
import Card from "../components/Card";
import ExpenseSummary from "../components/ExpensesSummary";
import MonthlyTransactionModal from "../components/modals/MonthlyTransactionCategoriesModal";
import { MonthlyBudgetFilters } from "../components/monthly-budget/Filters";
import BaseTable from "../components/monthly-budget/Table";
import ThreeDotsMenu from "../components/ThreeDotsMenu";
import { useMonthlyTransactions, useMonthlyTransactionsSummary } from "../hooks/useMonthlyTransactions";
import { MonthlyTransactionFetchParams } from "../models/types";
import { copyRecurrenceTransactions } from "../services/MonthlyTransactionService";
import { formatBrazilianCurrence } from "../utils/helper";
import { transactionColumns } from "./shared/TransactionsTable";


export default function TransactionsPage() {
    const [openCreateTransaction, setOpenCreateTransaction] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState<string | null>(null);
    const [params, setParams] = useState<MonthlyTransactionFetchParams>({});
    const [month, setMonth] = useState<string>(format(new Date(), "yyyy-MM-dd"));
    const { data: summary } = useMonthlyTransactionsSummary({ month, ...params });
    const handleAdvanceMonth = () => {
        setMonth(format(addMonths(parseISO(month), 1), "yyyy-MM-dd"));
    }
    const handlePreviousMonth = () => {
        setMonth(format(addMonths(parseISO(month), -1), "yyyy-MM-dd"));
    }

    const threeDotsItems = [
        {
            name: 'Adicionar transação', description: 'Adicione uma nova transação para o mês atual', onClick: () => {
                setOpenCreateTransaction(true)
                setSelectedTransaction(null);
            }, icon: PlusCircleIcon
        },
        {
            name: 'Importar despesas fixas', description: 'Importe todas suas despesas fixas para o mês atual',
            onClick: () => {
                copyRecurrenceTransactions({ month });
            }, icon: ClipboardDocumentListIcon
        },
    ]

    return <Card >
        <MonthlyTransactionModal
            transactionId={selectedTransaction}
            open={openCreateTransaction}
            onClose={() => {
                setOpenCreateTransaction(false)
                setSelectedTransaction(null);
            }}
        />
        <div className="flex gap-2 items-center w-full justify-between">
            <h1 className="text-xl font-bold capitalize">Transações</h1>
            <ThreeDotsMenu className="flex md:hidden" items={threeDotsItems} />
        </div>
        <h4 className="text-sm text-gray-500">
            Aqui você consegue organizar as despesas do mês, mapeie suas despesas, receitas, investimentos, controle o que já foi pago e o que ainda falta pagar.
        </h4>
        <div className="flex flex-row w-full items-end justify-between">
            <div className="flex gap-2 w-full flex-col">
                <label className="text-md font-bold">Mês</label>
                <div className="flex flex-row items-center gap-2">
                    <ArrowLeftCircleIcon onClick={() => handlePreviousMonth()} className="h-5 w-5 text-indigo-700 cursor-pointer" />
                    <h4 className="text-3xl text-gray-900">{format(parseISO(month), "MM/yyyy")}</h4>
                    <ArrowRightCircleIcon onClick={() => handleAdvanceMonth()} className="h-5 w-5 text-indigo-700 cursor-pointer" />
                </div>
            </div>
        </div>
        <div className="flex items-end md:items-center flex-col md:flex-row gap-4 justify-between ">
            <MonthlyBudgetFilters filters={params} setFilters={setParams} />
            <ThreeDotsMenu className="hidden md:flex" items={threeDotsItems} />
        </div>
        {summary && <ExpenseSummary values={[
            { label: "Pagamento Pendente", value: `R$ ${formatBrazilianCurrence(summary.pending_transactions_sum)}`, className: "text-gray-400" },
            { label: "Vencido", value: `R$ ${formatBrazilianCurrence(summary.due_transactions_sum)}`, className: "text-red-400" },
            { label: "Pago", value: `R$ ${formatBrazilianCurrence(summary.paid_transactions_sum)}`, className: "text-green-400" },
            { label: "Despesa Total", value: `R$ ${formatBrazilianCurrence(summary.total_transactions_sum)}`, className: "text-red-400" },
        ]} />}
        <BaseTable
            rowClassName={(row) => row.paid ? "bg-green-50" : ""}
            useGetData={useMonthlyTransactions}
            onRowClick={(row) => {
                setSelectedTransaction(row.id)
                setOpenCreateTransaction(true);
            }}
            columns={transactionColumns} params={{
                ...params,
                month
            }} />
    </Card>
}