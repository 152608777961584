import queryClient, { QueryKey } from "../hooks/state";
import { CreateMonthlyTransactionBody, MonthlyTransaction, MonthlyTransactionFetchParams, MonthlyTransactionSummary, MonthSummaryData, PaginatedResponse, UpdateMonthlyTransactionBody } from "../models/types";
import { httpDeleteAuthenticated, httpGetAuthenticated, httpPatchAuthenticated, httpPostAuthenticated } from "./Api";

const endpoints = {
  list: () => "/central/central/monthly_transactions",
  retrieve: (id: string) => `/central/central/monthly_transactions/${id}`,
};

export const getMonthlyTransaction = async (objectId: string) => {
  return httpGetAuthenticated<MonthlyTransaction>(endpoints.retrieve(objectId));
}

export const getMonthlyTransactions = async (params: MonthlyTransactionFetchParams) => {
  return httpGetAuthenticated<PaginatedResponse<MonthlyTransaction>>(endpoints.list(), {
    params,
  });
}

export const createMonthlyTransaction = async (payload: CreateMonthlyTransactionBody) => {
  const response = await httpPostAuthenticated<MonthlyTransaction>(endpoints.list(), payload);
  queryClient.invalidateQueries(QueryKey.monthlyTransaction);
  queryClient.invalidateQueries(QueryKey.monthlyTransactionSummary);
  return response.data;
}

export const updateMonthlyTransaction = async (id: string, payload: UpdateMonthlyTransactionBody) => {
  const response = await httpPatchAuthenticated(endpoints.retrieve(id), payload);
  queryClient.invalidateQueries(QueryKey.monthlyTransaction);
  queryClient.invalidateQueries(QueryKey.monthlyTransactionSummary);
  return response.data;
}

export const deleteMonthlyTransaction = async (id: string) => {
  const response = await httpDeleteAuthenticated(endpoints.retrieve(id));
  queryClient.invalidateQueries(QueryKey.monthlyTransaction);
  queryClient.invalidateQueries(QueryKey.monthlyTransactionSummary);
  return response.data;
}

export const copyRecurrenceTransactions = async (body: { month: string }) => {
  const response = await httpPostAuthenticated(endpoints.list() + "/copy_recurrence", body);
  queryClient.invalidateQueries(QueryKey.monthlyTransaction);
  return response.data;
}

export const getMonthlyTransactionSummary = async (params: MonthlyTransactionFetchParams) => {
  return await httpGetAuthenticated<MonthlyTransactionSummary>(endpoints.list() + "/summary", { params });
}

export const getMonthlyTransactionsSummaryByCategory = async (params: { month: string }) => {
  return await httpGetAuthenticated<PaginatedResponse<MonthSummaryData>>(endpoints.list() + "/month_summary", { params });
}