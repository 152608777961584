import { format } from "date-fns";
import { ptBR } from 'date-fns/locale';
import AISummary from "../components/AISummary";
import BudgetSummary from "../components/BudgetSummary";
import CourseCard from "../components/CourseCard";
import ExpenseSummary from "../components/ExpensesSummary";
import GoalSummary from "../components/GoalSummary";
import PaginatedCard from "../components/Pagination";
import StackedSummary from "../components/StackedSummary";
import { useCourses } from "../hooks/useCourses";
import { useMonthlyTransactionsSummary } from "../hooks/useMonthlyTransactions";
import { Course, CourseFilterParams } from "../models/types";
import { formatBrazilianCurrence } from "../utils/helper";

export default function HomePage() {
    const currentDate = new Date();
    const { data: summary } = useMonthlyTransactionsSummary({ month: format(currentDate, "yyyy-MM-dd") });
    const get_current_date = () => {
        return <h3 className="text-md font-semibold text-gray-900 capitalize">{format(currentDate, "MMMM/yyyy", { locale: ptBR })}</h3>
    }

    return <div className="bg-white shadow sm:rounded-lg overflow-hidden">
        <div className="px-4 py-5 sm:p-6 max-w-full">
            <div className="flex flex-col gap-4">

                {get_current_date()}
                <ExpenseSummary loading={!summary} values={[
                    { label: "Despesa Projetada", value: `R$ ${formatBrazilianCurrence(summary?.total_transactions_sum ?? 0)}`, className: "text-red-400" },
                    { label: "Pagamento Pendente", value: `R$ ${formatBrazilianCurrence(summary?.pending_transactions_sum ?? 0)}`, className: "text-gray-400" },
                    { label: "Vencido", value: `R$ ${formatBrazilianCurrence(summary?.due_transactions_sum ?? 0)}`, className: "text-red-400" },
                    { label: "Pago", value: `R$ ${formatBrazilianCurrence(summary?.paid_transactions_sum ?? 0)}`, className: "text-green-400" },
                ]} />

                <h3 className="text-md font-semibold text-gray-900">Resumo</h3>
                <div className="flex flex-wrap xl:flex-nowrap gap-4">
                    <AISummary />
                    <GoalSummary />
                    <BudgetSummary />
                </div>
                <StackedSummary />
                <h3 className="text-md font-semibold text-gray-900">Cursos em destaque</h3>
                <div className="text-sm text-gray-500">
                    Aqui estão nossos cursos cuidadosamente desenvolvidos para ajudar você a dominar o universo das finanças pessoais. Se você está começando a aprender sobre gestão financeira ou já tem experiência e deseja aprofundar seus conhecimentos, nossos cursos oferecem abordagens práticas e acessíveis. Desde conceitos básicos até estratégias avançadas, para ajudar você a tomar decisões financeiras mais conscientes e seguras.
                </div>
                <div className="flex w-full max-w-full flex-wrap gap-4">
                    <PaginatedCard<Course, CourseFilterParams>
                        useGetData={useCourses}
                        pageSize={5}
                        params={{ highlight: true }}
                        render={(course) => <CourseCard key={course.id} course={course} />} />
                </div>
            </div>
        </div>
    </div>
}