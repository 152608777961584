import { MonthlyTransaction, Transaction } from "../models/types"

export const formattedAmount = (transaction: Transaction | MonthlyTransaction) => {
    if (transaction?.category_name?.category_type === "expense") {
        return <h4 className="text-red-500"><span className="text-xs">R$</span> {formatBrazilianCurrence(transaction.amount)}</h4>
    } else if (transaction?.category_name?.category_type === "investment") {
        return <h4 className="text-red-500"><span className="text-xs">R$</span> {formatBrazilianCurrence(transaction.amount)}</h4>
    } else {
        return <h4 className="text-red-500"><span className="text-xs">R$</span> {formatBrazilianCurrence(transaction.amount)}</h4>
    }
}

export const getNameFirstLetters = (name: string) => {
    try {
        return name.split(" ").map((n) => n[0]).join("")
    } catch (e) {
        return "";
    }
}

export function formatBrazilianCurrence(value: number | string) {
    // Converte o número para string com duas casas decimais
    let numeroStr = "0";
    if (typeof value === 'string') {
        numeroStr = Number(value).toFixed(2);
    } else {
        numeroStr = value.toFixed(2);
    }

    // Divide a parte inteira da parte decimal
    let partes = numeroStr.split('.');
    let parteInteira = partes[0];
    let parteDecimal = partes[1];

    // Formata a parte inteira com pontos nos milhares
    let parteInteiraFormatada = parteInteira.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    // Junta a parte inteira formatada com a parte decimal
    return `${parteInteiraFormatada},${parteDecimal}`;
}

export const transactionCategoriesTypes: { name: string, id: "expense" | "income" | "investment" }[] = [{
    name: "Investimento",
    id: "investment"
}, {
    name: "Receita",
    id: "income"
}, {
    name: "Despesa",
    id: "expense"
}]