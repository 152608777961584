import queryClient, { QueryKey } from "../hooks/state";
import { DashboardData } from "../models/types";
import { httpGetAuthenticated } from "./Api";

const endpoints = {
  list: () => "/admin/dashboard",
};

export const getSummary = async () => {
  return httpGetAuthenticated<DashboardData>(endpoints.list() + "/summary");
}

export const refreshSummary = async () => {
  const response = await httpGetAuthenticated(endpoints.list() + "/refresh");
  await queryClient.invalidateQueries(QueryKey.dashboardSummary);
  return response
}