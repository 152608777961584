import { useQuery } from "react-query";
import { MonthlyTransactionFetchParams } from "../models/types";
import { getMonthlyTransaction, getMonthlyTransactions, getMonthlyTransactionSummary } from "../services/MonthlyTransactionService";
import { getMonthlyTransactionsSummaryByCategory } from './../services/MonthlyTransactionService';
import { QueryKey } from "./state";

export const useMonthlyTransaction = (objectId: string | null) => {
    return useQuery([QueryKey.monthlyTransaction, objectId], async () => {
        if (!objectId || (objectId && objectId === "")) return null;
        const { data } = await getMonthlyTransaction(objectId);
        return data;
    });
}

export const useMonthlyTransactions = (params: MonthlyTransactionFetchParams) => {
    return useQuery([QueryKey.monthlyTransaction, params], async () => {
        const { data } = await getMonthlyTransactions(params);
        return data;
    });
}

export const useMonthlyTransactionsSummary = (params: MonthlyTransactionFetchParams) => {
    return useQuery([QueryKey.monthlyTransactionSummary, params], async () => {
        const { data } = await getMonthlyTransactionSummary(params);
        return data;
    });
}

export const useGroupedMonthlyTransactionsSummary = (params: { month: string }) => {
    return useQuery([QueryKey.groupedMonthlyTransactions, params], async () => {
        const { data } = await getMonthlyTransactionsSummaryByCategory(params);
        return data;
    });
}