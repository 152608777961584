import { useQuery } from "react-query";
import { UserFetchParams } from "../models/types";
import { getAllUsers, getPaymentIntent, getUser, getUserSummary } from "../services/UserService";
import { QueryKey } from "./state";

export const useUser = () => {
    return useQuery([QueryKey.users], async () => {
        const { data } = await getUser();
        return data;
    });
}

export const usePaymentIntent = () => {
    return useQuery([QueryKey.paymentIntent], async () => {
        const { data } = await getPaymentIntent();
        return data;
    });
}

export const useAdminUsers = (params: UserFetchParams) => {
    return useQuery([QueryKey.users, "Admin", params], async () => {
        const { data } = await getAllUsers(params);
        return data;
    })
}

export const useUserSummary = (params: { id: number }) => {
    return useQuery([QueryKey.users, "summary", params], async () => {
        const { data } = await getUserSummary(params);
        return data;
    })
}